export const SET_BOXES = "SET_BOXES";
export const SET_BOXES_ERROR = "SET_BOXES_ERROR";
export const SET_BOXES_CURRENT_STATE = "SET_BOXES_CURRENT_STATE";
export const SET_BOXES_SELECTED_OBJECT = "SET_BOXES_SELECTED_OBJECT";
export const SET_BOXES_SEARCH = "SET_BOXES_SEARCH";
export const SET_BOXES_SORT = "SET_BOXES_SORT";

export const SET_ITEMS = "SET_ITEMS";
export const SET_ITEMS_ERROR = "SET_ITEMS_ERROR";
export const SET_ITEMS_CURRENT_STATE = "SET_ITEMS_CURRENT_STATE";
export const SET_ITEMS_SELECTED_OBJECT = "SET_ITEMS_SELECTED_OBJECT";
export const SET_ITEMS_SEARCH = "SET_ITEMS_SEARCH";
export const SET_ITEMS_SORT = "SET_ITEMS_SORT";

export const SET_STORAGE_LOCATIONS = "SET_STORAGE_LOCATIONS";
export const SET_STORAGE_LOCATIONS_ERROR = "SET_STORAGE_LOCATIONS_ERROR";
export const SET_STORAGE_LOCATIONS_CURRENT_STATE = "SET_STORAGE_LOCATIONS_CURRENT_STATE";
export const SET_STORAGE_LOCATIONS_SELECTED_OBJECT = "SET_STORAGE_LOCATIONS_SELECTED_OBJECT";

export const SET_EXPORT = "SET_EXPORT";
export const SET_EXPORT_ERROR = "SET_EXPORT_ERROR";
export const SET_EXPORT_CURRENT_STATE = "SET_EXPORT_CURRENT_STATE";
export const SET_EXPORT_FILTER = "SET_EXPORT_FILTER";

export const SET_FORM_PROCESSING = "SET_FORM_PROCESSING";
export const SET_FORM_ERROR = "SET_FORM_ERROR";
export const SET_FORM_SUCCESS = "SET_FORM_SUCCESS";
export const CLEAR_FORM_ERROR = "CLEAR_FORM_ERROR";
export const CLEAR_FORM_SUCCESS = "CLEAR_FORM_SUCCESS";
export const SET_FORM_TEMP_OBJECT = "SET_FORM_TEMP_OBJECT";
export const CLEAR_FORM_TEMP_OBJECT = "CLEAR_FORM_TEMP_OBJECT";

export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";