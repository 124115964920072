import {connect} from "react-redux";
import ShowBox from "../components/boxes/ShowBox";

function mapStateToProps(store : any) {
    return {
        store  : store.boxes
    }
}

const ShowBoxContainer = connect(mapStateToProps)(ShowBox);

export default ShowBoxContainer;