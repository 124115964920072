import React, {Component} from "react";
import {IExportTableProps} from "../../interfaces/interfaces";
import Download from "../shared/export/Download";
import ExportTableHeader from "../shared/export/ExportTableHeader";
import DataTable from "../shared/DataTable";

class ExportBoxes extends Component<IExportTableProps, any> {
    render(): React.ReactNode {
        let exportData = this.props.data;
        let headings = [
            {"name" : "Eindeutige Kennung", "sortkey" : "idBox"},
            {"name" : "Beschreibung", "sortkey" : "description"},
            {"name" : "In Verwendung", "sortkey" : "inUse"},
            {"name" : "Mit Deckel", "sortkey" : "withCover"},
            {"name" : "Stappelbar", "sortkey" : "stackable"},
            {"name" : "Typ der Kiste", "sortkey" : "boxType"},
            {"name" : "Hersteller", "sortkey" : "producer"},
            {"name" : "Farbe", "sortkey" : "color"},
            {"name" : "Grösse", "sortkey" : "size"},
            {"name" : "Lagerort", "sortkey" : "location.shortName"},
        ];
        let exportHeadings = [
            {label : "Eindeutige Kennung", key : "idBox", width: 64},
            {label : "Beschreibung", key : "description", width: 16},
            {label : "In Verwendung", key : "inUse", width: 16},
            {label : "Mit Deckel", key : "withCover", width: 64},
            {label : "Stappelbar", key : "stackable", width: 16},
            {label : "Typ der Kiste", key : "boxType", width: 40},
            {label : "Hersteller", key : "producer", width: 40},
            {label : "Farbe", key : "color", width: 40},
            {label : "Grösse", key : "size", width: 40},
            {label : "Lagerort", key : "box", width: 40}
        ];
        return (
            <>
                <ExportTableHeader object={this.props.object} />
                <Download headings={exportHeadings} data={exportData} fileName="Boxes" />
                <DataTable headings={headings} rows={exportData} onSort={null} />
            </>
        );
    }
}

export default ExportBoxes;