import * as types from "../actions/actionTypes";
import {IAction} from "../interfaces/actions";
import {getNewSortProperties} from "./helpers";

interface BoxReducerState {
    state: string,
    errorMessage: string,
    data: any[],
    selectedObject: object,
    searchString : string,
    sortOrder: string,
    sortedProperty: string
}

const defaultState = {
    data: [],
    state: "success",
    errorMessage: "",
    selectedObject: {},
    searchString : "",
    sortOrder: "ascending",
    sortedProperty: "description"
};

const boxReducer = (state : BoxReducerState = defaultState, action: IAction) => {
    switch (action.type) {
        case types.SET_BOXES_CURRENT_STATE:
            return {...state, state: action.data};
        case types.SET_BOXES_ERROR:
            return {...state, errorMessage: action.data, data: [], state: "error"};
        case types.SET_BOXES_SORT:
            let newProps = getNewSortProperties(state, action);
            return {...state, sortOrder: newProps.sortOrder, sortedProperty: newProps.sortedProperty};
        case types.SET_BOXES_SELECTED_OBJECT:
            return {...state, selectedObject : action.data};
        case types.SET_BOXES_SEARCH:
            return {...state, searchString: action.data };
        case types.SET_BOXES:
            return Object.assign({}, state, {
                data: action.data,
                state: "success"
            });
        default:
            return state;
    }
};

export default boxReducer;
