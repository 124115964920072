import React, {Component, Fragment} from "react";
import {IDataTablePaginationProps} from "../../../interfaces/interfaces";
import {range} from "../../../helpers/ComponentHelpers";

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

class DataTablePagination extends Component<IDataTablePaginationProps, any> {
    private readonly pageLimit : number;
    private totalRecords : number;
    private readonly pageNeighbours : number;
    private totalPages : number;

    constructor(props: IDataTablePaginationProps) {
        super(props);
        const { totalRecords = null, pageLimit = 30, pageNeighbours = 0 } = props;
        this.pageLimit = pageLimit;
        this.totalRecords = totalRecords === null ? 0 : totalRecords;
        this.pageNeighbours = Math.max(0, Math.min(pageNeighbours, 2));
        this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);
        this.state = {
            currentPage : 1
        };
    }

    componentDidUpdate(prevProps: Readonly<IDataTablePaginationProps>, prevState: Readonly<any>, snapshot?: any): void {
        if (this.state !== prevState || this.totalRecords === this.props.totalRecords) return;
        const { totalRecords = null, pageLimit = 30, pageNeighbours = 0 } = this.props;
        this.totalRecords = totalRecords === null ? 0 : totalRecords;
        this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);
        this.gotoPage(1);
    }

    componentDidMount(): void {
        this.gotoPage(1);
    }

    gotoPage = (page : number) => {
        const { onPageChanged = (f : any) => f } = this.props;
        const currentPage = Math.max(0, Math.min(page, this.totalPages));

        const Data = {
            currentPage,
            totalPages: this.totalPages,
            pageLimit: this.pageLimit,
            totalRecords: this.totalRecords
        };
        this.setState({currentPage}, () => onPageChanged(Data));
    };

    handleClick = (page : any) => {
        this.gotoPage(page);
    };

    handleMoveLeft = (event : any) => {
        event.preventDefault();
        this.gotoPage(this.state.currentPage - (this.pageNeighbours * 2) - 1);
    };

    handleMoveRight = (event : any) => {
        event.preventDefault();
        this.gotoPage(this.state.currentPage - (this.pageNeighbours * 2) - 1);
    };

    fetchPageNumbers = () => {
        const totalPages = this.totalPages;
        const currentPage = this.state.currentPage;
        const pageNeighbours = this.pageNeighbours;
        const totalNumbers = (this.pageNeighbours * 2) * 3;
        const totalBlocks = totalNumbers + 2;
        if (totalPages > totalBlocks){
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch(true){
                case (hasLeftSpill && !hasRightSpill): {
                    let extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }
                case (!hasLeftSpill && hasRightSpill): {
                    let extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }
            return [1, ...pages, totalPages];
        }
        return range(1, totalPages);
    };

    render() {
        if(!this.totalRecords || this.totalPages === 1) return null;
        const {currentPage} = this.state;
        const pages = this.fetchPageNumbers();
        return (
            <Fragment>
                <nav aria-label="Table Pagination">
                    <ul className="pagination">
                        {pages.map((page : any, index : number) => {
                            if (page === LEFT_PAGE) return (
                                <li key={index} className="page-item">
                                    <a className="page-link" href="#" aria-label="Previous" onClick={this.handleMoveLeft}>
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                </li>
                            );
                            if (page === RIGHT_PAGE) return (
                                <li key={index} className="page-item">
                                    <a className="page-link" href="#" aria-label="Next" onClick={this.handleMoveRight}>
                                        <span aria-hidden="true">&raquo;</span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </li>
                            );
                            return (
                                <li key={index} className={`page-item${currentPage === page ? ' active' : ''}`}>
                                    <a className="page-link" href="#" onClick={() => this.handleClick(page)}>{page}</a>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </Fragment>
        );
    };
}

export default DataTablePagination;