import React, {Component} from "react";
import {Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";
import * as sessionHelper from "../../helpers/sessionHelper";

class Navigation extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.state = {
            activeItem : ""
        }
    }

    static changeNavigation() : void {
        //store.dispatch(navigationActions.setCurrentSite(window.location.pathname));
        //store.dispatch(formActions.clearErrorMessage());
        //store.dispatch(formActions.clearSuccessMessage());
    }

    handleMenuItemClick = (e: any, {name}: any) => this.setState({activeItem: name});

    render(): React.ReactNode {
        const {activeItem} = this.state;

        return (
            <div className="main-navigation" onClick={Navigation.changeNavigation}>
                <Menu stackable>
                    <Menu.Item as={ Link } to="/" name='logo' onClick={this.handleMenuItemClick}>
                        <img src="../logo.png" alt="Logo Cevi Dürnten Materialverwaltung"/>
                    </Menu.Item>
                    {sessionHelper.NotLoggedIn() &&
                        <Menu.Item as={ Link } key="nav-login" to="/login" name='login' active={activeItem === 'login'} onClick={this.handleMenuItemClick}>
                            Login
                        </Menu.Item>
                    }
                    {sessionHelper.HasScoutingAccess() &&
                        <>
                            <Menu.Item as={ Link } key="nav-home" to="/" name='home' active={activeItem === 'home'} onClick={this.handleMenuItemClick}>
                                Startseite
                            </Menu.Item>
                            <Menu.Item as={ Link } key="nav-items" to="/items" name='items' active={activeItem === 'items'} onClick={this.handleMenuItemClick}>
                                Inventar
                            </Menu.Item>
                            <Menu.Item as={ Link } key="nav-exports" to="/exports" name='exports' active={activeItem === 'exports'} onClick={this.handleMenuItemClick}>
                                Exporte
                            </Menu.Item>
                            <Menu.Item as={ Link } key="nav-boxes" to="/boxes" name='boxes' active={activeItem === 'boxes'} onClick={this.handleMenuItemClick}>
                                Kisten
                            </Menu.Item>
                        </>
                    }
                    {sessionHelper.HasStuffAccess() &&
                        <>
                            <Menu.Item as={ Link } key="nav-locations" to="/locations" name='locations' active={activeItem === 'locations'} onClick={this.handleMenuItemClick}>
                                Lagerorte
                            </Menu.Item>
                        </>
                    }
                    {sessionHelper.LoggedIn() &&
                        <>
                            <Menu.Item as={ Link } key="nav-about" to="/about" name='about' active={activeItem === 'about'} onClick={this.handleMenuItemClick}>
                                Impressum
                            </Menu.Item>
                            <Menu.Item as={ Link } key="nav-logout" to="/logout" name='logout' active={activeItem === 'logout'} onClick={this.handleMenuItemClick}>
                                Logout
                            </Menu.Item>
                        </>
                    }
                </Menu>
            </div>
        );
    }
}

export default Navigation;