import React, {Component, Fragment} from "react";
import {Button, Header} from "semantic-ui-react";
import store from "../../store";
import * as itemActions from "../../actions/itemActions"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DataTable from "../shared/DataTable";
import LoadingSpinner from "../shared/spinner/LoadingSpinner";
import InfoWrapper from "../shared/error/InfoWrapper";
import {InfoWrapperTypes} from "../../global/InfoWrapperTypes";
import {isEmpty} from "../../helpers/ObjectHelper";
import FormMessageContainer from "../../containers/FormMessageContainer";
import EditItem from "./EditItem";
import DeleteItem from "./DeleteItem";
import AddItem from "./AddItem";

class Items extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.update = this.update.bind(this);
        this.onSearchFieldChanged = this.onSearchFieldChanged.bind(this);
    }
    componentDidMount(): void {
        this.update();
    }

    update(): void {
        store.dispatch(itemActions.getItems());
    }

    addItem(): void {
        store.dispatch(itemActions.addItemForm());
    }

    deleteItem(id : string) : void {
        store.dispatch(itemActions.getItemAndCallAction(id, "delete"));
    }

    editItem(id : string) : void {
        store.dispatch(itemActions.getItemAndCallAction(id, "edit"));
    }

    onSearchFieldChanged(event : any): void {
        let searchValue = event.target.value;
        store.dispatch(itemActions.onSearchChanged(searchValue));
    }

    onSortChanged(value : any) : void {
        store.dispatch(itemActions.onSortChanged(value));
    }

    renderActions(element : any) {
        return (
            <Fragment>
                <a href="#" onClick={() => this.deleteItem(element.idItem)}><FontAwesomeIcon icon={['fas', 'trash']} /> Löschen</a>
                <a href="#" onClick={() => this.editItem(element.idItem)}><FontAwesomeIcon icon={['fas', 'edit']} /> Bearbeiten</a>
            </Fragment>
        )
    }

    addActions(items : any) : void {
        items.map((element : any) => {
            element.action = this.renderActions(element);
        });
    }

    render() {
        const {store, items} = this.props;
        this.addActions(items);
        let headings = [
            {"name" : "Menge", "sortkey" : "quantity"},
            {"name" : "Einheit", "sortkey" : "quantityType"},
            {"name" : "Beschreibung", "sortkey" : "description"},
            {"name" : "Lagerort", "sortkey" : "location"},
            {"name" : "Kiste", "sortkey" : "box"},
            {"name" : "Aktionen", "sortkey" : "action"}
        ];
        return(
            <>
                <Header as="h1" className="mainHeader">Inventar</Header>
                {isEmpty(store.selectedObject) && store.state !== "add" &&
                    <div className="button-group">
                        <Button onClick={this.addItem} type="button" ><FontAwesomeIcon className="button-icon" icon={['fas', 'plus']} />Objekt hinzufügen</Button>
                        <Button onClick={this.update} type="button" ><FontAwesomeIcon className="button-icon" icon={['fas', 'sync']} />Liste neuladen</Button>
                        <input type="text" className="searchField" name="search" placeholder="Filtern nach..." id="search" value={store.searchString} onChange={this.onSearchFieldChanged} />
                    </div>
                }
                <FormMessageContainer/>
                {(() => {
                    switch (store.state) {
                        case "success":
                            return <DataTable headings={headings} rows={items} onSort={this.onSortChanged} />;
                        case "loading":
                            return <LoadingSpinner/>;
                        case "error":
                            return <InfoWrapper title="Laden der Daten nicht erfolgreich" info={store.errorMessage} type={InfoWrapperTypes.FAILURE} />;
                        case "edit":
                            return <EditItem initalValues={store.selectedObject}/>;
                        case "delete":
                            return <DeleteItem initalValues={store.selectedObject}/>;
                        case "add":
                            return <AddItem />;
                        default:
                            return null;
                    }
                })()}
            </>
        );
    }
}

export default Items;