import * as types from "./actionTypes";
import * as api from "../api/api";
import * as forms from "./formActions";
import store from "../store";

function receiveItems(data : any) {
    return {
        type : types.SET_ITEMS,
        data : data
    }
}

function receiveItemsError(errorMessage : string) {
    return {
        type: types.SET_ITEMS_ERROR,
        data: errorMessage
    }
}

function changeState(state : string) {
    return {
        type : types.SET_ITEMS_CURRENT_STATE,
        data : state
    }
}

function setSelectedObject(item : object) {
    return {
        type : types.SET_ITEMS_SELECTED_OBJECT,
        data : item
    }
}

function clearSelectedObject() {
    return {
        type : types.SET_ITEMS_SELECTED_OBJECT,
        data : {}
    }
}

function changeSearchString(data : string) {
    return {
        type : types.SET_ITEMS_SEARCH,
        data : data
    }
}

function changeSortingOfData(data : any) {
    return {
        type : types.SET_ITEMS_SORT,
        data : data
    }
}

export function backToList(): any {
    return function (dispatch : any) {
        dispatch(changeState("success"));
        dispatch(setSelectedObject({}));
    }
}

export function getItemAndCallAction(id : string, action : string) : any {
    return function (dispatch : any) {
        getItemFromAPI(id, dispatch).then(() => {
            dispatch(changeState(action));
        });
    }
}

export function addItemForm(): any {
    return function (dispatch : any) {
        dispatch(changeState("add"));
    }
}

function getItemsFromAPI(dispatch : any) : any {
    dispatch(changeState("loading"));
    api.getItems().then(function (res) {
        dispatch(receiveItems(res));
    }).catch(function (error) {
        dispatch(receiveItemsError(error));
    });
}

function getItemFromAPI(id : string, dispatch : any) {
    return new Promise((resolve, reject) => {
        dispatch(changeState("loading"));
        api.getItem(id).then(function (res) {
            res.box = res.box === null ? {} : res.box;
            res.location = res.location === null ? {} : res.location;
            dispatch(setSelectedObject(res));
            resolve();
        }).catch(function (error) {
            dispatch(receiveItemsError(error));
            reject();
        });
    });
}

export function getItems() : any {
    return function (dispatch : any) {
        getItemsFromAPI(dispatch);
    }
}

export function deleteItem(id : string): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.deleteItem(id).then(function (res) {
            dispatch(forms.setFormSuccess("Das Element wurde erfolgreich gelöscht."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getItemsFromAPI(dispatch);
        }).catch(function (error) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function addItem(values : any): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.addItem(values).then(function (res : any) {
            dispatch(forms.setFormSuccess("Das Element wurde erfolgreich hinzugefügt."));
            dispatch(changeState("success"));
            getItemsFromAPI(dispatch);
        }).catch(function (error : any) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function editItem(values : any): any {
    return function (dispatch: any) {
        dispatch(forms.setFormProcessing());
        api.editItem(values).then(function (res: any) {
            dispatch(forms.setFormSuccess("Die Änderungen am Element wurde erfolgreich gespeichert."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getItemsFromAPI(dispatch);
        }).catch(function (error: any) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function onSearchChanged(searchString : any): any {
    return function (dispatch : any) {
        dispatch(changeSearchString(searchString));
        let successMessage = (store.getState()as any).form.successMessage;
        if (searchString !== "" && successMessage !== "") {
            dispatch(forms.clearSuccessMessage());
        }
    }
}

export function onSortChanged(props : any): any {
    return function (dispatch : any) {
        dispatch(changeSortingOfData(props));
    }
}
