import {combineReducers} from "redux";
import formReducer from "./formReducer";
import boxReducer from "./boxReducer";
import itemReducer from "./itemReducer";
import storageLocationReducer from "./storageLocationReducer";
import exportReducer from "./exportReducer";
import currentUserReducer from "./currentUserReducer";

const rootReducer = combineReducers({
    boxes: boxReducer as any,
    items : itemReducer as any,
    locations : storageLocationReducer as any,
    form : formReducer as any,
    export : exportReducer as any,
    currentUser : currentUserReducer as any
});

export default rootReducer;