import React, {Component} from "react";
import {IExportTableProps} from "../../interfaces/interfaces";
import Download from "../shared/export/Download";
import ExportTableHeader from "../shared/export/ExportTableHeader";
import DataTable from "../shared/DataTable";

class ExportItems extends Component<IExportTableProps, any> {
    render(): React.ReactNode {
        let exportData = this.props.data;
        let headings = [
            {"name" : "Eindeutige Kennung", "sortkey" : "idItem"},
            {"name" : "Menge", "sortkey" : "quantity"},
            {"name" : "Einheit", "sortkey" : "quantityType"},
            {"name" : "Beschreibung", "sortkey" : "description"},
            {"name" : "Preis pro Einheit", "sortkey" : "price"},
            {"name" : "Lagerort", "sortkey" : "location"},
            {"name" : "Kiste", "sortkey" : "box"},
        ];
        let exportHeadings = [
            {label : "Eindeutige Kennung", key : "idItem", width: 64},
            {label : "Menge", key : "quantity", width: 16},
            {label : "Einheit", key : "quantityType", width: 16},
            {label : "Beschreibung", key : "description", width: 64},
            {label : "Preis pro Einheit", key : "price", width: 16},
            {label : "Lagerort", key : "location", width: 40},
            {label : "Kiste", key : "box", width: 40}
        ];
        return (
            <>
                <ExportTableHeader object={this.props.object} />
                <Download headings={exportHeadings} data={exportData} fileName="Items" />
                <DataTable headings={headings} rows={exportData} onSort={null} />
            </>
        );
    }
}

export default ExportItems;