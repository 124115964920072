import React, {Component} from "react";
import App from "./App";
import 'semantic-ui-css/semantic.min.css';
import '../styles/main.scss';
import store from "../store";
import * as boxActions from "../actions/boxActions";
import * as locationActions from "../actions/locationActions";
import * as sessionHelper from "../helpers/sessionHelper";

class Global extends Component<any, any> {
    componentDidMount(): void {
        if (sessionHelper.LoggedIn()) {
            store.dispatch(boxActions.getBoxes());
            store.dispatch(locationActions.getLocations());
        }
    }

    render(): React.ReactNode {
        return <App/>
    }
}

export default Global;