import React, {Component} from "react";
import {ITabsProps} from "../../../interfaces/interfaces";

class Tabs extends Component<ITabsProps, any> {
    constructor(props: ITabsProps) {
        super(props);
        this.state = {
            selected : this.props.selected
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(index : number, child: any) {
        this.setState({
            selected: index
        });
        if (child.props.onClick !== undefined && child.props.onClick !== null){
            child.props.onClick();
        }
    }

    _renderTitles(){
        const elements = React.Children.toArray(this.props.children).filter((c : any) => c.props.show);
        let container = this;
        function labels(child: any, index: number) {
            let activeClass = (container.state.selected === index ? 'active' : '');
            if (child === undefined || child === true || child === false) return null;
            return (
                <li key={index} className={activeClass} onClick={() => container.handleClick(index, child)}>
                    {container.props.withNumbers ? (
                        <a href="#">{index+1}.&nbsp;&nbsp;{child.props.label}</a>
                    ) : (
                        <a href="#">{child.props.label}</a>
                    )}
                </li>
            );
        }
        return (
            <ul className="tabs">
                {elements.map(labels.bind(this))}
            </ul>
        );
    }

    _renderContent(){
        const elements = React.Children.toArray(this.props.children).filter((c : any) => c.props.show);
        if(this.state.selected === undefined) {
            return (<div className="no-tab-selected">
                <p>Bitte wählen Sie einen Tab aus um Informationen zu erhalten und/oder Aktionen vorzunehmen.</p>
            </div>);
        }
        return (
            <div>{elements[this.state.selected]}</div>
        );
    }

    render() {
        let additonalClasses = this.props.className !== undefined ? this.props.className : "";
        let classNames = "tabs-wrapper " + additonalClasses;
        return (
            <div className={classNames}>
                {this._renderTitles()}
                {this._renderContent()}
            </div>
        );
    };
}

export default Tabs;