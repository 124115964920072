import * as types from "./actionTypes";
import * as api from "../api/api";
import * as forms from "./formActions";
import store from "../store";

function receiveBoxes(data : any) {
    return {
        type : types.SET_BOXES,
        data : data
    }
}

function receiveBoxesError(errorMessage : string) {
    return {
        type: types.SET_BOXES_ERROR,
        data: errorMessage
    }
}

function changeState(state : string) {
    return {
        type : types.SET_BOXES_CURRENT_STATE,
        data : state
    }
}

function setSelectedObject(box : object) {
    return {
        type : types.SET_BOXES_SELECTED_OBJECT,
        data : box
    }
}

function clearSelectedObject() {
    return {
        type : types.SET_BOXES_SELECTED_OBJECT,
        data : {}
    }
}

function changeSearchString(data : string) {
    return {
        type : types.SET_BOXES_SEARCH,
        data : data
    }
}

function changeSortingOfData(data : any) {
    return {
        type : types.SET_BOXES_SORT,
        data : data
    }
}

export function backToList(): any {
    return function (dispatch : any) {
        dispatch(changeState("success"));
        dispatch(setSelectedObject({}));
    }
}

export function getBoxAndCallAction(id : string, action : string) : any {
    return function (dispatch : any) {
        getBoxFromAPI(id, dispatch).then(() => {
            dispatch(changeState(action));
        });
    }
}

export function addBoxForm(): any {
    return function (dispatch : any) {
        dispatch(changeState("add"));
    }
}

function getBoxesFromAPI(dispatch : any) : any {
    dispatch(changeState("loading"));
    api.getBoxes().then(function (res) {
        dispatch(receiveBoxes(res));
    }).catch(function (error) {
        dispatch(receiveBoxesError(error));
    });
}

function getBoxFromAPI(id : string, dispatch : any) {
    return new Promise((resolve, reject) => {
        dispatch(changeState("loading"));
        api.getBox(id).then(function (res) {
            res.inUse = res.inUse ? "true" : "false";
            res.withCover = res.withCover ? "true" : "false";
            res.stackable = res.stackable ? "true" : "false";
            dispatch(setSelectedObject(res));
            resolve();
        }).catch(function (error) {
            dispatch(receiveBoxesError(error));
            reject();
        });
    });
}

export function getBoxes() : any {
    return function (dispatch : any) {
        getBoxesFromAPI(dispatch);
    }
}

export function deleteBox(id : string): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.deleteBox(id).then(function (res) {
            dispatch(forms.setFormSuccess("Die Kiste wurde erfolgreich gelöscht."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getBoxesFromAPI(dispatch);
        }).catch(function (error) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function addBox(values : any): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.addBox(values).then(function (res : any) {
            dispatch(forms.setFormSuccess("Die Kiste wurde erfolgreich hinzugefügt."));
            dispatch(changeState("success"));
            getBoxesFromAPI(dispatch);
        }).catch(function (error : any) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function editBox(values : any): any {
    return function (dispatch: any) {
        dispatch(forms.setFormProcessing());
        api.editBox(values).then(function (res: any) {
            dispatch(forms.setFormSuccess("Die Änderungen an der Kiste wurde erfolgreich gespeichert."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getBoxesFromAPI(dispatch);
        }).catch(function (error: any) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function onSearchChanged(searchString : any): any {
    return function (dispatch : any) {
        dispatch(changeSearchString(searchString));
        let successMessage = (store.getState()as any).form.successMessage;
        if (searchString !== "" && successMessage !== "") {
            dispatch(forms.clearSuccessMessage());
        }
    }
}

export function onSortChanged(props : any): any {
    return function (dispatch : any) {
        dispatch(changeSortingOfData(props));
    }
}