import React, {Component} from "react";
import {IExportTableHeaderProps} from "../../../interfaces/interfaces";

class ExportTableHeader extends Component<IExportTableHeaderProps, any> {
    render(): React.ReactNode {
        return (
            <>
                <div className="header-export-table">
                    <h2>{this.props.object.title}</h2>
                    <p>{this.props.object.description}</p>
                </div>
            </>
        );
    }
}

export default ExportTableHeader;