import * as types from "../actions/actionTypes";
import {IAction} from "../interfaces/actions";

interface ExportReducerState {
    state: string,
    errorMessage: string,
    data: any[],
    exportType: string,
}

const defaultState = {
    data: [],
    state: "success",
    errorMessage: "",
    exportType: "",
};

const exportReducer = (state : ExportReducerState = defaultState, action: IAction) => {
    switch (action.type) {
        case types.SET_EXPORT_CURRENT_STATE:
            return {...state, state: action.data};
        case types.SET_EXPORT_ERROR:
            return {...state, errorMessage: action.data, data: [], state: "error"};
        case types.SET_EXPORT_FILTER:
            return {...state, exportType: action.data };
        case types.SET_EXPORT:
            return Object.assign({}, state, {
                data: action.data,
                state: "success"
            });
        default:
            return state;
    }
};

export default exportReducer;
