export function getSorted(items : any, options : any, searchOptions? : any) : any {
    let arrayCopy = items;
    if (items === undefined || items === null) return arrayCopy;
    // If search string is supplied, filter the elements by that
    if (searchOptions !== undefined && searchOptions !== null && searchOptions.searchString !== "") {
        arrayCopy = filterByMultipleProperties(searchOptions.searchString, searchOptions.searchKeys, arrayCopy);
    }
    arrayCopy.sort(compareBy(options.sortKey));
    if (options.sortOrder === "descending"){
        arrayCopy.reverse();
    }
    return arrayCopy;
}

export function getSortedSimple(items : any, options : any) : any {
    let arrayCopy = items;
    if (items !== undefined && items !== null) {
        arrayCopy.sort(compareBy(options.sortkey));
        if (options.sortorder === "descending"){
            arrayCopy.reverse();
        }
    }
    return arrayCopy;
}

function compareBy(key : any){
    return function (a : any, b : any) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0
    };
}

function filterByMultipleProperties(searchString : string, keys : any, elements : any) : any {
    let searchValue = searchString.toLowerCase();
    let res = elements.filter(function (element : any) {
        return keys.some((key : any) => {
            if (key.toString().includes(".")) {
                let first = key.toString().substring(0, key.toString().indexOf("."));
                let second = key.toString().substring(key.toString().indexOf(".") + 1, key.toString().length);
                if (element[first] !== null) {
                    return String(element[first][second]).toLowerCase().includes(searchValue)
                }
            }
            return String(element[key]).toLowerCase().includes(searchValue)
        });
    });
    return res;
}