import React, {Component} from "react";
import {Table} from "semantic-ui-react";
import {IDataTableProps} from "../../interfaces/interfaces";
import DataTablePagination from "./table/DataTablePagination";
import {isBoolean} from "../../helpers/ComponentHelpers";

class DataTable extends Component<IDataTableProps, any> {
    constructor(props: IDataTableProps) {
        super(props);
        this.renderDataRow.bind(this);
        this.renderHeadingCell.bind(this);
        this.state = {
            currentElements: [],
            currentPage: null,
            totalPages: null,
            pageLimit: null
        };
    }

    onSortChanged(value : any) : void {
        if (value === "action") return;
        if (this.props.onSort != null) this.props.onSort(value);
        const {currentPage, totalPages, pageLimit} = this.state;
        const {rows} = this.props;
        const offset = (currentPage - 1) * pageLimit;
        const currentElements = rows.slice(offset, offset + pageLimit);
        this.setState({currentPage, currentElements, totalPages});
    }

    renderHeadingCell = (_cell : any, cellIndex : number) => {
        let sort = this.props.onSort !== null;
        if (_cell.sortkey === "action" || _cell.sortkey === "deleted") {
            return <Table.HeaderCell className="cell-header" key={`heading-${cellIndex}`}>{_cell.name}</Table.HeaderCell>;
        } else {
            return <Table.HeaderCell className="cell-header" key={`heading-${cellIndex}`} onClick={() => this.onSortChanged(_cell.sortkey)}>{_cell.name}</Table.HeaderCell>;
        }
    };

    renderDataRow = (_row : [], rowIndex : number) => {
        let keys = this.props.headings.map((element : any) => {
            return element.sortkey;
        });
        return [
            <Table.Row key={rowIndex}>
                {keys.map((key : any, index : number) => {
                    if (key.toString().includes(".")) {
                        let first = key.toString().substring(0, key.toString().indexOf("."));
                        let second = key.toString().substring(key.toString().indexOf(".") + 1, key.toString().length);
                        if (_row[first] !== null) {
                            return (
                                <Table.Cell className={key} key={`${index}-${rowIndex}`}>{_row[first][second]}</Table.Cell>
                            )
                        }
                    }
                    if (isBoolean(_row[key])) {
                        let value = _row[key] ? "x" : "";
                        return (<Table.Cell className={key} key={`${index}-${rowIndex}`}>{value}</Table.Cell>);
                    }
                    return (
                        <Table.Cell className={key} key={`${index}-${rowIndex}`}>{_row[key]}</Table.Cell>
                    )
                })}
            </Table.Row>
        ]
    };

    componentDidUpdate(prevProps: Readonly<IDataTableProps>, prevState: Readonly<any>, snapshot?: any): void {
        if (this.state !== prevState) return;
        const {currentPage, pageLimit} = this.state;
        const offset = (currentPage - 1) * pageLimit;
        const currentElements = this.props.rows.slice(offset, offset + pageLimit);
        let pages = this.props.rows.length / 20;
        if (pages === 0) pages = 1;
        this.setState({currentPage, currentElements, "totalPages" : pages, pageLimit});
    }

    onPageChange = (data : any) => {
        const {rows} = this.props;
        const {currentPage, totalPages, pageLimit} = data;
        const offset = (currentPage - 1) * pageLimit;
        const currentElements = rows.slice(offset, offset + pageLimit);
        this.setState({currentPage, currentElements, totalPages, pageLimit});
    };

    render() {
        const {headings, rows} = this.props;
        const {currentElements} = this.state;
        const theadMarkup = headings.map(this.renderHeadingCell);
        if (rows === undefined || rows === null || rows.length === 0) return <div className="datatable-wrapper"><br/><div className="noresult">Keine Resulate</div></div>;
        const tBodyMarkup = currentElements.map(this.renderDataRow);
        return (
            <div className="datatable-wrapper" key="datatable">
                <Table basic='very' className="datatable">
                    <Table.Header>
                        <Table.Row>
                            {theadMarkup}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {tBodyMarkup}
                    </Table.Body>
                </Table>
                <DataTablePagination totalRecords={rows.length}  pageLimit={15} pageNeighbours={1} onPageChanged={this.onPageChange} />
            </div>
        );
    };
}

export default DataTable;