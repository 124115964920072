import store from "../store";

export function doLocationList(): any {
    const {data} = (store.getState() as any).locations;
    let items = data;
    let array : any[] = [];
    if (items !== undefined) {
        items.map((element : any) => {
            let obj : any = {};
            obj.value = element.idStorageLocation;
            obj.text = element.shortName;
            array.push(obj)
        });
    }
    return array;
}

export function doBoxList(): any {
    const {data} = (store.getState() as any).boxes;
    let items = data;
    let array : any[] = [];
    if (items !== undefined) {
        items.map((element : any) => {
            let obj : any = {};
            obj.value = element.idBox;
            obj.text = element.description;
            array.push(obj)
        });
    }
    return array;
}

export function getQuantityTypeList() : any {
    let array : any[] = [];
    array.push({value : 1, text : "STK"});
    array.push({value : 2, text : "LITER"});
    array.push({value : 3, text : "METER"});
    array.push({value : 4, text : "KISTEN"});
    return array;
}

