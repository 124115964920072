import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as boxActions from "../../actions/boxActions";
import TitleHelper from "../shared/TitleHelper";
import {IFormProps} from "../../interfaces/interfaces";
import store from "../../store";
import {Button, Form as FormStyled} from "semantic-ui-react";
import FormError from "../shared/forms/FormError";

class DeleteBox extends Component<IFormProps, DeleteBoxFormValues> {
    constructor(props : any){
        super(props);
        this.state = {
            idBox: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }
    onCancel(): void {
        store.dispatch(boxActions.backToList());
    }

    handleSubmit(values : DeleteBoxFormValues, actions : FormikHelpers<DeleteBoxFormValues>) : void {
        store.dispatch(boxActions.deleteBox(values.idBox));
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Kiste löschen"/>
                <h2>Kiste löschen</h2>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="idBox">Kisten ID</label>
                                <Field type="hidden" name="idBox"/>
                                <p>{this.state.idBox}</p>
                                <FormError name="idBox" />
                            </FormStyled.Field>
                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Kiste löschen</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default DeleteBox;