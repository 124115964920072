import * as types from "./actionTypes";
import * as api from "../api/api";
import * as forms from "./formActions";

function receiveLocations(data : any) {
    return {
        type : types.SET_STORAGE_LOCATIONS,
        data : data
    }
}

function receiveLocationsError(errorMessage : string) {
    return {
        type: types.SET_STORAGE_LOCATIONS_ERROR,
        data: errorMessage
    }
}

function changeState(state : string) {
    return {
        type : types.SET_STORAGE_LOCATIONS_CURRENT_STATE,
        data : state
    }
}

function setSelectedObject(location : object) {
    return {
        type : types.SET_STORAGE_LOCATIONS_SELECTED_OBJECT,
        data : location
    }
}

function clearSelectedObject() {
    return {
        type : types.SET_STORAGE_LOCATIONS_SELECTED_OBJECT,
        data : {}
    }
}

export function backToList(): any {
    return function (dispatch : any) {
        dispatch(changeState("success"));
        dispatch(setSelectedObject({}));
    }
}

export function getLocationAndCallAction(id : string, action : string) : any {
    return function (dispatch : any) {
        getLocationFromAPI(id, dispatch).then(() => {
            dispatch(changeState(action));
        });
    }
}

export function addLocationForm(): any {
    return function (dispatch : any) {
        dispatch(changeState("add"));
    }
}

function getLocationsFromAPI(dispatch : any) : any {
    dispatch(changeState("loading"));
    api.getLocations().then(function (res) {
        dispatch(receiveLocations(res));
    }).catch(function (error) {
        dispatch(receiveLocationsError(error));
    });
}

function getLocationFromAPI(id : string, dispatch : any) {
    return new Promise((resolve, reject) => {
        dispatch(changeState("loading"));
        api.getLocation(id).then(function (res) {
            dispatch(setSelectedObject(res));
            resolve();
        }).catch(function (error) {
            dispatch(receiveLocationsError(error));
            reject();
        });
    });
}

export function getLocations() : any {
    return function (dispatch : any) {
        getLocationsFromAPI(dispatch);
    }
}

export function deleteLocation(id : string): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.deleteLocation(id).then(function (res) {
            dispatch(forms.setFormSuccess("Der Lagerort wurde erfolgreich gelöscht."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getLocationsFromAPI(dispatch);
        }).catch(function (error) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function addLocation(values : any): any {
    return function (dispatch : any) {
        dispatch(forms.setFormProcessing());
        api.addLocation(values).then(function (res : any) {
            dispatch(forms.setFormSuccess("Der Lagerort wurde erfolgreich hinzugefügt."));
            dispatch(changeState("success"));
            getLocationsFromAPI(dispatch);
        }).catch(function (error : any) {
            dispatch(forms.setFormError(error));
        });
    }
}

export function editLocation(values : any): any {
    return function (dispatch: any) {
        dispatch(forms.setFormProcessing());
        api.editLocation(values).then(function (res: any) {
            dispatch(forms.setFormSuccess("Die Änderungen am Lagerort wurden erfolgreich gespeichert."));
            dispatch(changeState("success"));
            dispatch(clearSelectedObject());
            getLocationsFromAPI(dispatch);
        }).catch(function (error: any) {
            dispatch(forms.setFormError(error));
        });
    }
}