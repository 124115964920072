import * as types from "./actionTypes";
import * as api from "../api/api";

function receiveExport(data : any) {
    return {
        type : types.SET_EXPORT,
        data : data
    }
}

function receiveExportError(errorMessage : string) {
    return {
        type: types.SET_EXPORT_ERROR,
        data: errorMessage
    }
}

function changeState(state : string) {
    return {
        type : types.SET_EXPORT_CURRENT_STATE,
        data : state
    }
}

function clearExport() {
    return {
        type : types.SET_BOXES_SELECTED_OBJECT,
        data : {}
    }
}

function changeFilteringOfData(data : any) {
    return {
        type: types.SET_EXPORT_FILTER,
        data: data
    }
}

function getExportBoxesFromAPI(dispatch : any) : any {
    dispatch(changeState("loading"));
    dispatch(clearExport());
    api.getExport("Boxes").then(function (res) {
        dispatch(receiveExport(res));
    }).catch(function (error) {
        dispatch(receiveExportError(error));
    });
}

function getExportItemsFromAPI(dispatch : any) : any {
    dispatch(changeState("loading"));
    dispatch(clearExport());
    api.getExport("All").then(function (res) {
        dispatch(receiveExport(res));
    }).catch(function (error) {
        dispatch(receiveExportError(error));
    });
}

function getExportItemsWithLocationFromAPI(dispatch : any, location : string) : any {
    dispatch(changeState("loading"));
    dispatch(clearExport());
    api.getExport(location).then(function (res) {
        dispatch(receiveExport(res));
    }).catch(function (error) {
        dispatch(receiveExportError(error));
    });
}


export function getItems() : any {
    return function (dispatch : any) {
        getExportItemsFromAPI(dispatch);
    }
}

export function getBoxes() : any {
    return function (dispatch : any) {
        getExportBoxesFromAPI(dispatch);
    }
}

export function getItemsByLocation(location : string) : any {
    return function (dispatch : any) {
        getExportItemsWithLocationFromAPI(dispatch, location);
    }
}