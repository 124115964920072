import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as itemActions from "../../actions/itemActions";
import * as Yup from "yup";
import {IFormProps} from "../../interfaces/interfaces";
import store from "../../store";
import TitleHelper from "../shared/TitleHelper";
import {Button, Form as FormStyled} from "semantic-ui-react";
import FormError from "../shared/forms/FormError";
import SimpleSelectListForm from "../shared/forms/SimpleSelectListForm";
import {doBoxList, doLocationList, getQuantityTypeList} from "../../helpers/SelectListHelpers";


class AddItem extends Component<IFormProps, CreateItemFormValues> {
    constructor(props : any) {
        super(props);
        this.state = {
            box: {},
            description: "",
            price: 0,
            quantity: 0,
            quantityType: 1,
            location: {}
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }

    validationSchemaData = Yup.object().shape({
        quantity : Yup.number().required("* Feld ist erforderlich"),
        quantityType : Yup.number().required("* Feld ist erforderlich"),
        description : Yup.string().required("* Feld ist erforderlich")
    });

    onCancel(): void {
        store.dispatch(itemActions.backToList());
    }

    handleSubmit(values : CreateItemFormValues, actions : FormikHelpers<CreateItemFormValues>) : void {
        store.dispatch(itemActions.addItem(values));
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Objekt hinzufügen"/>
                <h2>Objekt hinzufügen</h2>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit} validationSchema={this.validationSchemaData}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="description">Beschreibung</label>
                                <Field type="text" name="description" placeHolder="C-Blache (pink)"/>
                                <FormError name="description" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="quantity">Menge</label>
                                <Field type="number" name="quantity" placeHolder="10"/>
                                <FormError name="quantity" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="quantityType">Einheit</label>
                                <SimpleSelectListForm options={getQuantityTypeList()} selectName="quantityType" selectedValue={this.state.quantityType} defaultText="-- Einheit auswählen --" />
                                <FormError name="quantityType" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="price">Wert pro Einheit (CHF)</label>
                                <Field type="number" name="price" placeHolder="250.00" step="any"/>
                                <FormError name="price" />
                            </FormStyled.Field>

                            <FormStyled.Field>
                                <label htmlFor="location.idStorageLocation">Lagerort (nur Ausfüllen, wenn nicht in einer Kiste)</label>
                                <SimpleSelectListForm options={doLocationList()} selectName="location.idStorageLocation" selectedValue={(this.state.location as any).idStorageLocation} defaultText="-- Lagerort auswählen --" />
                                <FormError name="location.idStorageLocation" />
                            </FormStyled.Field>

                            <FormStyled.Field>
                                <label htmlFor="box.idBox">Kiste (nur bei Bedarf ausfüllen)</label>
                                <SimpleSelectListForm options={doBoxList()} selectName="box.idBox" selectedValue={(this.state.box as any).idBox} defaultText="-- Einheit auswählen --" />
                                <FormError name="box.idBox" />
                            </FormStyled.Field>

                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Kiste erstellen</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default AddItem;