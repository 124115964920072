import React, {Component} from "react";

class Copyright extends Component {
    render(): React.ReactNode {
        return (
            <div className="copyright">
                <p>(c) by Cevi Dürnten 2020, Version 1.6.0 | stuff.ceviduernten.ch</p>
            </div>
        );
    }
}

export default Copyright;