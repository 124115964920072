import React, {Component} from "react";
import {IDownloadProps} from "../../../interfaces/interfaces";
import moment from "moment";
// @ts-ignore
import ReactExport from "react-data-export";
import {isBoolean} from "../../../helpers/ComponentHelpers";

class Download extends Component<IDownloadProps, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        let currentDate = moment().format("DD-MM-YYYY");
        let fileName = this.props.fileName + "_" + currentDate;
        let excelHeadings: any[] = [];
        let excelData: any[] = [];
        this.props.headings.map((element : any, index : number) => {
            excelHeadings.push({
                title: element.label, width: {wch: element.width}, style: { font: { bold: true }}
            });
        });
        this.props.data.map((element : any) => {
            let obj: any[] = [];
            this.props.headings.map(function (heading : any) {
                let value = element[heading.key];
                if (value !== undefined && value !== null) {
                    if (isBoolean(value)) {
                        let b = value ? "x" : "";
                        obj.push({value: b.toString()});
                    } else {
                        obj.push({value: value.toString()});
                    }
                } else {
                    obj.push({value: ""});
                }
            });
            excelData.push(obj);
        });
        const multiDataSet = [
            {
                columns: excelHeadings,
                data: excelData
            }
        ];
        return (
            <ReactExport.ExcelFile element={<button className="download-button">Export als .xlsx herunterladen</button>} filename={fileName}>
                <ReactExport.ExcelSheet dataSet={multiDataSet} name="EXPORT" />
            </ReactExport.ExcelFile>
        );
    };
}

export default Download;