export function range(from :  number, to : number, step = 1) : any {
    let i = from;
    const range = [];

    while(i <= to){
        range.push(i);
        i += step;
    }

    return range;
}

export function isBoolean(val : any) {
    return val === false || val === true;
}