import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as boxActions from "../../actions/boxActions";
import * as Yup from "yup";
import store from "../../store";
import TitleHelper from "../shared/TitleHelper";
import FormError from "../shared/forms/FormError";
import {IFormProps} from "../../interfaces/interfaces";
import {Button, Checkbox, Form as FormStyled} from "semantic-ui-react";
import SimpleSelectListForm from "../shared/forms/SimpleSelectListForm";
import {doLocationList} from "../../helpers/SelectListHelpers";

class EditBox extends Component<IFormProps, EditBoxFormValues> {
    constructor(props : any) {
        super(props);
        this.state = {
            location: {},
            boxType: "",
            color: "",
            description: "",
            idBox: "",
            inUse: "false",
            producer: "",
            stackable: "false",
            withCover: "false",
            size: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    validationSchemaData = Yup.object().shape({
        color : Yup.string().required("* Feld ist erforderlich"),
        boxType : Yup.string().required("* Feld ist erforderlich"),
        producer : Yup.string().required("* Feld ist erforderlich"),
        description : Yup.string().required("* Feld ist erforderlich"),
        size : Yup.string(),
        idBox : Yup.string().required("* Feld ist erforderlich"),
    });

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }

    onCancel(): void {
        store.dispatch(boxActions.backToList());
    }

    handleSubmit(values : EditBoxFormValues, actions : FormikHelpers<EditBoxFormValues>) : void {
        store.dispatch(boxActions.editBox(values));
    }

    handleCheckboxChange(e : any, { value } : any) : void {
        console.log(value);
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Kiste bearbeiten"/>
                <h2>Kiste bearbeiten</h2>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit} validationSchema={this.validationSchemaData}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="idStorageLocation">Kisten ID</label>
                                <Field type="hidden" name="idStorageLocation"/>
                                <p>{this.state.idBox}</p>
                                <FormError name="idStorageLocation" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="description">Beschreibung</label>
                                <Field type="text" name="description" placeHolder="Text"/>
                                <FormError name="description" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="boxType">Kistentyp (Freitext)</label>
                                <Field type="text" name="boxType" placeHolder="Text"/>
                                <FormError name="boxType" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="producer">Hersteller</label>
                                <Field type="text" name="producer" placeHolder="UTZ"/>
                                <FormError name="producer" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="color">Farbe</label>
                                <Field type="text" name="color" placeHolder="Rot"/>
                                <FormError name="color" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="size">Grösse</label>
                                <Field type="text" name="size" placeHolder="40x30x17"/>
                                <FormError name="size" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="location.idStorageLocation">Lagerort</label>
                                <SimpleSelectListForm options={doLocationList()} selectName="location.idStorageLocation" selectedValue={(this.state.location as any).idStorageLocation} defaultText="Lagerort auswählen"  />
                                <FormError name="location.idStorageLocation" />
                            </FormStyled.Field>


                            <FormStyled.Field>
                                <label htmlFor="stackable">Stappelbar</label>
                                <Field>
                                    {(bag : any) => (
                                        <>
                                            <Checkbox radio label='JA' name='stackable' value='true' checked={bag.form.values.stackable === 'true'} onChange={() => bag.form.setFieldValue("stackable", "true")} />
                                            <Checkbox radio label='NEIN' name='stackable' value='false' checked={bag.form.values.stackable === 'false'} onChange={() => bag.form.setFieldValue("stackable", "false")}  />
                                        </>
                                    )}
                                </Field>
                                <FormError name="stackable" />
                            </FormStyled.Field>


                            <FormStyled.Field>
                                <label htmlFor="inUse">In Benutzung</label>
                                <Field>
                                    {(bag : any) => (
                                        <>
                                            <Checkbox radio label='JA' name='inUse' value='true' checked={bag.form.values.inUse === 'true'} onChange={() => bag.form.setFieldValue("inUse", "true")} />
                                            <Checkbox radio label='NEIN' name='inUse' value='false' checked={bag.form.values.inUse === 'false'} onChange={() => bag.form.setFieldValue("inUse", "false")}  />
                                        </>
                                    )}
                                </Field>
                                <FormError name="inUse" />
                            </FormStyled.Field>

                            <FormStyled.Field>
                                <label htmlFor="withCover">Mit Deckel</label>
                                <Field>
                                    {(bag : any) => (
                                        <>
                                            <Checkbox radio label='JA' name='withCover' value='true' checked={bag.form.values.withCover === 'true'} onChange={() => bag.form.setFieldValue("withCover", "true")} />
                                            <Checkbox radio label='NEIN' name='withCover' value='false' checked={bag.form.values.withCover === 'false'}  onChange={() => bag.form.setFieldValue("withCover", "false")} />
                                        </>
                                    )}
                                </Field>
                                <FormError name="withCover" />
                            </FormStyled.Field>

                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Kiste speichern</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default EditBox;