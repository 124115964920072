import React, {Component} from "react";
import {IGroupSelectListProps} from "../../interfaces/interfaces";
import SelectList from "./SelectList";

class LocationSelectList extends Component<IGroupSelectListProps, any> {
    render() {
        let properties = ["idStorageLocation", "shortName"];
        return (
            <SelectList options={this.props.options} class="customer-list" onChange={this.props.onChange} selectedValue={this.props.selectedValue} properties={properties} defaultText="bitte Lagerort auswählen" />
        );
    };
}

export default LocationSelectList;