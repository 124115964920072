import axios from "axios";
import * as apiLinks from "./apiLinks";
import store from "../store";

function getAPIConfigurationSecured() {
    const token = (store.getState() as any).currentUser.token;
    return axios.create({
        timeout: 1000000,
        responseType: "json",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Pragma': 'no-cache',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*'
        }
    });
}

function getAPIConfigurationUnsecured() {
    return axios.create({
        timeout: 10000,
        responseType: "json",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Pragma': 'no-cache',
            'Access-Control-Allow-Origin': '*'
        }
    });
}

// Items
export function getItems() {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url: apiLinks.ITEMS,
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

export function deleteItem(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.delete(apiLinks.ITEMS + "/" + id, {})
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function editItem(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.patch(apiLinks.ITEMS + "/" + values.idItem, values)
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function getItem(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url : apiLinks.ITEMS + "/" + id,
            method: 'get',
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Es konnte keine Verbindung zum Server hergestellt werden.");
        })
    });
}

export function addItem(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.post(apiLinks.ITEMS, values)
            .then(function (response : any) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

// Items
export function getBoxes() {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url: apiLinks.BOXES,
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

export function deleteBox(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.delete(apiLinks.BOXES + "/" + id, {})
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function editBox(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.patch(apiLinks.BOXES + "/" + values.IdBox, values)
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function getBox(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationUnsecured();
        return apiClient({
            url : apiLinks.BOXES + "/" + id,
            method: 'get',
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Es konnte keine Verbindung zum Server hergestellt werden.");
        })
    });
}

export function addBox(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.post(apiLinks.BOXES, values)
            .then(function (response : any) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

// Storage Locations
export function getLocations() {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url: apiLinks.STORAGE_LOCATIONS,
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

export function deleteLocation(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.delete(apiLinks.STORAGE_LOCATIONS + "/" + id, {})
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function editLocation(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.patch(apiLinks.STORAGE_LOCATIONS + "/" + values.idStorageLocation, values)
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

export function getLocation(id : string) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url : apiLinks.STORAGE_LOCATIONS + "/" + id,
            method: 'get',
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Es konnte keine Verbindung zum Server hergestellt werden.");
        })
    });
}

export function addLocation(values : any) {
    return new Promise<any>((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient.post(apiLinks.STORAGE_LOCATIONS, values)
            .then(function (response : any) {
                if (response.data.statusCode === 200) {
                    resolve(response.data.data);
                } else {
                    reject(response.data.message);
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}

// Export
export function getExport(type : string) {
    return new Promise<any>(((resolve, reject) => {
        let apiClient = getAPIConfigurationSecured();
        return apiClient({
            url: apiLinks.EXPORTS + "/" + type,
            method: 'get'
        }).then(function (response) {
            if (response.data.statusCode === 200) {
                resolve(response.data.data);
            } else {
                reject(response.data.message);
            }
        }).catch(function (error) {
            reject("Daten konnten nicht geladen werden");
        })
    }));
}

// Login

export function login(user : any) {
    return new Promise((resolve, reject) => {
        let apiClient = getAPIConfigurationUnsecured();
        return apiClient.post(apiLinks.USERS + "/Login", user)
            .then(function (response : any) {
                if (response.data.statusCode === 200 && response.data.data.token !== "") {
                    resolve(response.data.data);
                } else {
                    reject("Kombination von Benutzer und Passwort nicht gefunden.");
                }
            }).catch(function (error) {
                reject("Es konnte keine Verbindung zum Server hergestellt werden.");
            })
    });
}