export function getNewSortProperties(state : any, action : any) : any {
    let obj = {
        "sortOrder" : state.sortOrder, "sortedProperty" : state.sortedProperty
    };
    if (state.sortedProperty !== action.data) {
        obj.sortedProperty = action.data;
        obj.sortOrder = "ascending";
    } else if (state.sortedProperty === action.data) {
        obj.sortOrder = (state.sortOrder === "ascending") ? "descending" : "ascending";
    }
    return obj;
}