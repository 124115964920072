import {connect} from "react-redux";
import Items from "../components/items/Items";
import {getSorted} from "../selectors/basic";

function mapStateToProps(store : any) {
    let items = store.items.data;
    let searchString = store.items.searchString;
    let searchOptions = {"searchKeys" : ['description', 'idItem', 'quantity', 'box', 'location'], "searchString" : searchString };
    return {
        store  : store.items,
        items : getSorted(items, {"sortKey" : store.items.sortedProperty, "sortOrder" : store.items.sortOrder }, searchOptions),
    }
}

const ItemsContainer = connect(mapStateToProps)(Items);

export default ItemsContainer;