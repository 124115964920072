import React, {Component} from "react";
import {IBoxInformationProps} from "../../interfaces/interfaces";

class BoxInformation extends Component<IBoxInformationProps, any> {
    render() {
        return (
            <div className="box-information">
                <span className="box-information-title">{this.props.title}:</span>
                <span className="box-information-content">{this.props.content}</span>
            </div>
        );
    };
}

export default BoxInformation;