import React, {Component} from "react";
import {Card, Header} from "semantic-ui-react";

class Home extends Component {
    render() {
        return(
            <>
                <Header as="h1" className="mainHeader">Home</Header>
                <div className="content">
                    <p>Wähle den entsprechenden Bereich aus.</p>
                    <Header as="h3">Bereiche</Header>
                    <br/>
                    <Card.Group>
                        <Card
                            href='/items'
                            header='Inventar'
                            description='Gesamtes Inventar vom Cevi Dürnten'
                        />
                        <Card
                            href='/exports'
                            header='Exporte'
                            description='Exports von Materiallisten ins Excel'
                        />
                        <Card
                            href='/boxes'
                            header='Kisten'
                            description='Kisten im Bestand vom Cevi Dürnten und deren Inhalt'
                        />
                        <Card
                            href='/locations'
                            header='Lagerorte'
                            description='Verwaltung der Lagerorte'
                        />
                    </Card.Group>

                    <Header as="h3">Fragen, Unklarheiten oder Ideen?</Header>
                    <p>Wende dich an das Matieralteam unter material@ceviduernten.ch</p>
                </div>
            </>
        );
    }
}

export default Home;