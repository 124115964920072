export function isEmpty(obj : any) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function isDefined<T>(value : T | undefined | null) : value is T {
    return value as T !== undefined && value as T != null;
}