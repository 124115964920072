import React, {Component, Fragment} from "react";
import {Button, Header} from "semantic-ui-react";
import store from "../../store";
import * as boxActions from "../../actions/boxActions"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DataTable from "../shared/DataTable";
import LoadingSpinner from "../shared/spinner/LoadingSpinner";
import InfoWrapper from "../shared/error/InfoWrapper";
import {InfoWrapperTypes} from "../../global/InfoWrapperTypes";
import {isEmpty} from "../../helpers/ObjectHelper";
import FormMessageContainer from "../../containers/FormMessageContainer";
import EditBox from "./EditBox";
import DeleteBox from "./DeleteBox";
import AddBox from "./AddBox";

class Boxes extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.update = this.update.bind(this);
        this.onSearchFieldChanged = this.onSearchFieldChanged.bind(this);
    }
    componentDidMount(): void {
        this.update();
    }

    update(): void {
        store.dispatch(boxActions.getBoxes());
    }

    addBox(): void {
        store.dispatch(boxActions.addBoxForm());
    }

    deleteBox(id : string) : void {
        store.dispatch(boxActions.getBoxAndCallAction(id, "delete"));
    }

    editBox(id : string) : void {
        store.dispatch(boxActions.getBoxAndCallAction(id, "edit"));
    }

    onSearchFieldChanged(event : any): void {
        let searchValue = event.target.value;
        store.dispatch(boxActions.onSearchChanged(searchValue));
    }

    onSortChanged(value : any) : void {
        store.dispatch(boxActions.onSortChanged(value));
    }

    renderActions(element : any) {
        return (
            <Fragment>
                <a href="#" onClick={() => this.deleteBox(element.idBox)}><FontAwesomeIcon icon={['fas', 'trash']} /> Löschen</a>
                <a href="#" onClick={() => this.editBox(element.idBox)}><FontAwesomeIcon icon={['fas', 'edit']} /> Bearbeiten</a>
            </Fragment>
        )
    }

    addActions(items : any) : void {
        items.map((element : any) => {
            element.action = this.renderActions(element);
        });
    }

    render() {
        const {store, boxes} = this.props;
        this.addActions(boxes);
        let headings = [
            {"name" : "ID", "sortkey" : "idBox"},
            {"name" : "Beschreibung", "sortkey" : "description"},
            {"name" : "Lagerort", "sortkey" : "location"},
            {"name" : "Aktionen", "sortkey" : "action"}
        ];
        return(
            <>
                <Header as="h1" className="mainHeader">Kisten</Header>
                {isEmpty(store.selectedObject) && store.state !== "add" &&
                    <div className="button-group">
                        <Button onClick={this.addBox} type="button" ><FontAwesomeIcon className="button-icon" icon={['fas', 'plus']} />Kiste hinzufügen</Button>
                        <Button onClick={this.update} type="button" ><FontAwesomeIcon className="button-icon" icon={['fas', 'sync']} />Liste neuladen</Button>
                        <input type="text" className="searchField" name="search" placeholder="Filtern nach..." id="search" value={store.searchString} onChange={this.onSearchFieldChanged} />
                    </div>
                }
                <FormMessageContainer/>
                {(() => {
                    switch (store.state) {
                        case "success":
                            return <DataTable headings={headings} rows={boxes} onSort={this.onSortChanged} />;
                        case "loading":
                            return <LoadingSpinner/>;
                        case "error":
                            return <InfoWrapper title="Laden der Daten nicht erfolgreich" info={store.errorMessage} type={InfoWrapperTypes.FAILURE} />;
                        case "edit":
                            return <EditBox initalValues={store.selectedObject}/>;
                        case "delete":
                            return <DeleteBox initalValues={store.selectedObject}/>;
                        case "add":
                            return <AddBox />;
                        default:
                            return null;
                    }
                })()}
            </>
        );
    }
}

export default Boxes;