import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as locationActions from "../../actions/locationActions";
import TitleHelper from "../shared/TitleHelper";
import {IFormProps} from "../../interfaces/interfaces";
import store from "../../store";
import {Button, Form as FormStyled} from "semantic-ui-react";
import FormError from "../shared/forms/FormError";

class DeleteLocation extends Component<IFormProps, DeleteLocationFormValues> {
    constructor(props : any){
        super(props);
        this.state = {
            idStorageLocation: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }
    onCancel(): void {
        store.dispatch(locationActions.backToList());
    }

    handleSubmit(values : DeleteLocationFormValues, actions : FormikHelpers<DeleteLocationFormValues>) : void {
        store.dispatch(locationActions.deleteLocation(values.idStorageLocation));
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Lagerort löschen"/>
                <h2>Lagerort löschen</h2>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="idStorageLocation">Lagerort ID</label>
                                <Field type="hidden" name="idStorageLocation"/>
                                <p>{this.state.idStorageLocation}</p>
                                <FormError name="idStorageLocation" />
                            </FormStyled.Field>
                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Lagerort löschen</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default DeleteLocation;