import {connect} from "react-redux";
import Boxes from "../components/boxes/Boxes";
import {getSorted} from "../selectors/basic";

function mapStateToProps(store : any) {
    let boxes = store.boxes.data;
    let searchString = store.boxes.searchString;
    let searchOptions = {"searchKeys" : ['description', 'idBox', 'location'], "searchString" : searchString };
    return {
        store  : store.boxes,
        boxes : getSorted(boxes, {"sortKey" : store.boxes.sortedProperty, "sortOrder" : store.boxes.sortOrder }, searchOptions),
    }
}

const BoxesContainer = connect(mapStateToProps)(Boxes);

export default BoxesContainer;