import {connect} from "react-redux";
import Exports from "../components/exports/Exports";

function mapStateToProps(store : any) {
    return {
        store  : store.export,
        locations : store.locations.data
    }
}

const ExportsContainer = connect(mapStateToProps)(Exports);

export default ExportsContainer;