import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as exportActions from "../../actions/exportActions";
import ExportItems from "./ExportItems";
import ExportBoxes from "./ExportBoxes";
import LoadingSpinner from "../shared/spinner/LoadingSpinner";
import InfoWrapper from "../shared/error/InfoWrapper";
import {InfoWrapperTypes} from "../../global/InfoWrapperTypes";
import TitleHelper from "../shared/TitleHelper";
import {Button, Header} from "semantic-ui-react";
import store from "../../store";
import Tabs from "../shared/tabs/Tabs";
import Pane from "../shared/tabs/Pane";
import LocationSelectList from "../shared/LocationSelectList";

class Exports extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.state = {
            idLocation: "",
            exportType : ""
        };
        this.update = this.update.bind(this);
        this.getItems = this.getItems.bind(this);
        this.getBoxes = this.getBoxes.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
    }
    componentDidMount(): void {
        this.update();
    }

    update(): void {
        switch(this.state.exportType) {
            case "items":
                console.log("items");
                store.dispatch(exportActions.getItems());
                break;
            case "boxes":
                store.dispatch(exportActions.getBoxes());
                break;
            case "itemsperlocation":
                store.dispatch(exportActions.getItemsByLocation(this.state.idLocation));
                break;
            default:
                // NOTHING TO DO
        }
    }

    onFilterChanged(event : any) : void {
        let filter = event.target.value;
        if (filter === ""){
            this.setState({'idLocation' : ""});
        } else {
            this.setState({'idLocation' : filter});
            store.dispatch(exportActions.getItemsByLocation(filter))
        }
    }

    getItems() : void {
        this.setState({'exportType' : "items"});
        store.dispatch(exportActions.getItems());
    }

    getBoxes() : void {
        this.setState({'exportType' : "boxes"});
        store.dispatch(exportActions.getBoxes());
    }

    getItemsPerLocation() : void {
        this.setState({'exportType' : "itemsperlocation"});
    }

    exportItems(store : any) {
        switch (store.state) {
            case "success":
                return <ExportItems data={store.data.data} object={store.data} />;
            case "loading":
                return <LoadingSpinner/>;
            case "error":
                return <InfoWrapper title="Laden der Daten nicht erfolgreich" info={store.errorMessage} type={InfoWrapperTypes.FAILURE} />;
            default:
                return null;
        }
    }

    exportBoxes(store : any) {
        switch (store.state) {
            case "success":
                return <ExportBoxes data={store.data.data} object={store.data} />;
            case "loading":
                return <LoadingSpinner/>;
            case "error":
                return <InfoWrapper title="Laden der Daten nicht erfolgreich" info={store.errorMessage} type={InfoWrapperTypes.FAILURE} />;
            default:
                return null;
        }
    }

    exportItemsPerLocation(store : any, locations : any) {
        switch (store.state) {
            case "success":
                if (this.state.idLocation !== "") return <ExportItems data={store.data.data} object={store.data} />;
                else return <></>;
            case "loading":
                return <LoadingSpinner/>;
            case "error":
                return <InfoWrapper title="Laden der Daten nicht erfolgreich" info={store.errorMessage} type={InfoWrapperTypes.FAILURE} />;
            default:
                return null;
        }
    }

    render(): React.ReactNode {
        const {store, locations} = this.props;
        return (
            <>
                <TitleHelper title="Exporte"/>
                <Header as="h1" className="mainHeader">Exporte</Header>
                <div className="button-group">
                    <Button onClick={this.update} type="button" ><FontAwesomeIcon icon={['fas', 'sync']} />Daten neuladen</Button>
                    {this.state.exportType === "itemsperlocation" &&
                        <LocationSelectList options={locations} onChange={this.onFilterChanged} />
                    }
                    <br/><br/>
                </div>
                <Tabs className="export">
                    <Pane label="Gesamtinventar" onClick={() => this.getItems()}>
                        {this.exportItems(store)}
                    </Pane>
                    <Pane label="Kisten" onClick={() => this.getBoxes()}>
                        {this.exportBoxes(store)}
                    </Pane>
                    <Pane label="Materialliste je Lagerort" onClick={() => this.getItemsPerLocation()}>
                        {this.exportItemsPerLocation(store, locations)}
                    </Pane>
                </Tabs>
            </>
        );
    }
}

export default Exports;