import React, {Component} from "react";
import {IPaneProps} from "../../../interfaces/interfaces";


class Pane extends Component<IPaneProps, any> {
    static defaultProps = {
        show: true
    };

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    };
}

export default Pane;