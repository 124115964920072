import React, {Component} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as locationActions from "../../actions/locationActions";
import * as Yup from "yup";
import {IFormProps} from "../../interfaces/interfaces";
import store from "../../store";
import TitleHelper from "../shared/TitleHelper";
import {Button, Form as FormStyled} from "semantic-ui-react";
import FormError from "../shared/forms/FormError";


class AddLocation extends Component<IFormProps, CreateLocationFormValues> {
    constructor(props : any) {
        super(props);
        this.state = {
            address: "", city: "", description: "", shortName: "", zip: 0
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount(): void {
        if (this.props.initalValues !== undefined) {
            this.setState(this.props.initalValues);
        }
    }

    validationSchemaData = Yup.object().shape({
        address : Yup.string().required("* Feld ist erforderlich"),
        city : Yup.string().required("* Feld ist erforderlich"),
        shortName : Yup.string().required("* Feld ist erforderlich"),
        description : Yup.string(),
        zip : Yup.number().required("* Feld ist erforderlich"),
    });

    onCancel(): void {
        store.dispatch(locationActions.backToList());
    }

    handleSubmit(values : CreateLocationFormValues, actions : FormikHelpers<CreateLocationFormValues>) : void {
        store.dispatch(locationActions.addLocation(values));
    }

    render(): React.ReactNode {
        return (
            <div className="form-wrapper">
                <TitleHelper title="Lagerort hinzufügen"/>
                <h2>Lagerort hinzufügen</h2>
                <Formik enableReinitialize initialValues={this.state} onSubmit={this.handleSubmit} validationSchema={this.validationSchemaData}>
                    <Form className="form">
                        <br/>
                        <FormStyled>
                            <FormStyled.Field>
                                <label htmlFor="shortName">Kürzel</label>
                                <Field type="text" name="shortName" placeHolder="METZG"/>
                                <FormError name="shortName" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="address">Adresse</label>
                                <Field type="text" name="address" placeHolder="Mustergasse 1"/>
                                <FormError name="address" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="zip">PLZ</label>
                                <Field type="number" name="zip" placeHolder="1234"/>
                                <FormError name="zip" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="city">Ort</label>
                                <Field type="text" name="city" placeHolder="Musterdorf"/>
                                <FormError name="city" />
                            </FormStyled.Field>
                            <FormStyled.Field>
                                <label htmlFor="description">Beschreibung</label>
                                <Field type="text" name="description" placeHolder="Text"/>
                                <FormError name="description" />
                            </FormStyled.Field>
                            <Button className="first warning" onClick={this.onCancel} type="button">Aktion abbrechen</Button>
                            <Button className="success" type="submit">Lagerort erstellen</Button>
                        </FormStyled>
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default AddLocation;