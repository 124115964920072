import React, {Component} from "react";
import * as boxActions from "../../actions/boxActions";
import store from "../../store";
import TitleHelper from "../shared/TitleHelper";
import {isDefined, isEmpty} from "../../helpers/ObjectHelper";
import {Header} from "semantic-ui-react";
import BoxInformation from "../shared/BoxInformation";

class ShowBox extends Component<any, any> {
    componentWillMount(): void {
        let id = this.props.match.params.guid;
        console.log(id);
        if (isDefined(id)) {
            store.dispatch(boxActions.getBoxAndCallAction(id, "show"));
        }
    }

    render(): React.ReactNode {
        const {store} = this.props;
        if (store.selectedObject === null || store.selectedObject === undefined || isEmpty(store.selectedObject)){
            // No box to display, display an error.
            return <p className="box-information">Keine Kiste gefunden</p>;
        }
        let box = store.selectedObject;
        return (
            <div className="details-wrapper">
                <TitleHelper title="Kiste"/>
                <Header as="h1" className="mainHeader">Kiste "{box.description}"</Header>
                <h3 className="box-header">Informationen</h3>
                <BoxInformation title={"Beschreibung"} content={box.description} />
                <BoxInformation title={"Lagerort"} content={box.location.shortName} />
                <BoxInformation title={"Boxtyp"} content={box.boxType} />
                <BoxInformation title={"Hersteller"} content={box.producer} />
                <BoxInformation title={"Farbe"} content={box.color} />
                <BoxInformation title={"Grösse"} content={box.size} />
                <h3 className="box-header">Inhalt der Kiste</h3>
                {box.items.length === 0 &&
                    <p className="box-information">Kein Inhalt in dieser Kiste</p>
                }
                {box.items.length >= 0 &&
                    <ul>
                        {box.items.map((elem : any) => {
                            return <li>{elem.quantity} <b>{elem.quantityTypeString}</b> {elem.description}</li>;
                        })}
                    </ul>
                }
            </div>
        );
    }
}

export default ShowBox;